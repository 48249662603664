'use strict';

{
	let footnoteTimer = null;
	let region = null;
	let returnElement = null;
	let returnProp = null;
	let eventFn = () => {
		if(returnElement !== null)
		{
			if(footnoteTimer !== null)
			{
				clearTimeout(footnoteTimer);        
			}

			footnoteTimer = setTimeout(function()
			{
				window.removeEventListener('scroll', eventFn);
				
				if(returnElement !== null)
				{
					returnElement.focus();
					returnElement.removeAttribute('data-reference-return');
					returnElement = null;

					returnProp = null;
					
					region.removeAttribute('data-return-prop');
					region = null;
				}
			}, 50);
		}
	};

	document.addEventListener('click', click => {
		let selector = `${window.namespace}-footnotes__list-return`;
		
		if(click.target.closest(`.${selector}`))
		{
			click.preventDefault();

			let arrow = click.target.closest(`.${selector}`);

			returnElement = document.querySelector('[data-reference-return]');

			if(returnElement.closest(`.${window.namespace}--sticky`))
			{
				region = returnElement.closest(`.${window.namespace}-section[data-return-prop]`);

				if(region)
				{
					let propId = parseInt(region.dataset.returnProp);

					returnProp = region.querySelector(`.${window.namespace}-section__prop[data-region-prop='${propId}']`);
				}
			}

			arrow.setAttribute('hidden', '');

			if(returnElement)
			{
				returnElement.removeAttribute('data-reference-return');
				
				let y = window.scrollY;
				
				if(returnProp)
				{
					y += Math.floor(returnProp.getBoundingClientRect().top);
				}
				
				else
				{
					y += Math.floor(returnElement.getBoundingClientRect().top);
				}
				
				if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
				{
					let header = document.getElementById('header');

					y -= (header.getBoundingClientRect().height * 2);
				}

				window.addEventListener('scroll', eventFn);

				window.scrollTo({
					behavior: 'smooth',
					top: y
				});
			}

			return false;
		}
	});
}