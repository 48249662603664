'use strict';

{	
	let classname = `${window.namespace}-checkcard`;
	
	window.do.always(() => {
		let selector = `${classname}:not([data-assigned])`;

		if(document.querySelectorAll(`.${selector}`).length)
		{
			document.querySelectorAll(`.${selector}`).forEach(checkcard => 
			{
				checkcard.validate = function()
				{
					let selector = this.querySelectorAll(`[required]`);

					if(!selector.length)
					{
						checkcard.classList.remove(`${classname}--invalid`);

						return true;
					}

					if(!this.querySelectorAll(`.${classname}__native[required]:checked`).length)
					{
						this.classList.add(`${classname}--invalid`);
					}
					
					else
					{
						this.classList.remove(`${classname}--invalid`);
					}
				};
				
				{
					let inputs = checkcard.querySelectorAll(`.${classname}__native`);
					
					inputs.forEach(input => {
						[
							'input',
							'blur'
						].forEach(eventListener => {
							input.addEventListener(eventListener, (event) => {
								if(/\-\-invalid\s?/gi.test(checkcard.getAttribute('class')))
								{
									checkcard.validate();
								}
							});
						});
					});
				}

				checkcard.dataset.assigned = `${window.namespace}`;
			});
		}
	});
}