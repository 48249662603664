'use strict';

{
	async function when(condition)
	{
		if(typeof condition === 'string')
		{
			let selector = condition;

			while(document.querySelector(selector) === null)
			{
				await new Promise(resolve => requestAnimationFrame(resolve))
			}

			return document.querySelector(selector); 
		}

		else
		{
			while(condition === null || condition === false)
			{
				await new Promise(resolve => requestAnimationFrame(resolve))
			}

			return condition;
		}
	};

	module.exports.when = when;
}