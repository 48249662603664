'use strict';

{
	let regionId = 'how-it-works';

	window.do.when(`#${regionId}`).then(region => {
		let classname = `${window.namespace}-module`;
		let module = region.querySelector(`.${classname}`);

		if(module)
		{
			let contentMenu = module.querySelector(`.${classname}__menu`);
			let contentHeader = module.querySelector(`.${classname}__header`);
			let contentRoll = module.querySelector(`.${classname}__content-roll`);

			if(contentRoll)
			{
				lottie:
				{
					let targetElements = module.querySelectorAll('lottie-player');
					
					targetElements.forEach(lottie => { 
						let link = lottie.querySelector('link[as][href][rel="preload"]');

						if(link)
						{
							let config = {
								container: lottie,
								path: link.getAttribute('href'), 
								renderer: 'svg'
							};

							if(config.path)
							{
								config.autoplay = Boolean(lottie.getAttribute('autoplay'));
								config.loop = Boolean(lottie.getAttribute('loop'));
 
								lottie.innerHTML = '';

								window.lottie.loadAnimation(config);
							}
						}
					});
				}
				
				set_size:
				{
					let targetContent = contentRoll.querySelector('p:not([aria-hidden])');

					if(targetContent)
					{
						contentRoll.style.setProperty('height', `${targetContent.getBoundingClientRect().height}px`);

						[
							'resize',
							'orientationchange'
						].forEach(eventlistener => {
							window.addEventListener(eventlistener, event => {
								let targetContent = contentRoll.querySelector('p:not([aria-hidden])');

								contentRoll.style.setProperty('height', `${targetContent.getBoundingClientRect().height}px`);
							});
						});
					}
				}

				set_menu_behavior:
				{
					contentMenu.querySelectorAll(`.${classname}__menu-item`).forEach((item,index) => {	
						let id = item.getAttribute('href').replace(/#/gi,'');

						item.addEventListener('click', click => {
							click.preventDefault();

							if(document.getElementById(id))
							{
								let prop = document.getElementById(id);

								prop.focus(
								{
									preventScroll: true
								});

								window.scrollToElement(prop, false);
							}

							return false;
						});
					});
				}

				contentRoll.setTo = function(n)
				{
					let targetContent = this.querySelector(`p:nth-child(${n})`);

					if(targetContent)
					{
						let oldContent = contentRoll.querySelector('p:not([aria-hidden])');
						oldContent.setAttribute('aria-hidden', true);

						this.style.setProperty('height', `${targetContent.getBoundingClientRect().height}px`);

						targetContent.removeAttribute('aria-hidden');

						if(contentHeader)
						{
							let currentGraphic = (contentHeader.querySelector('lottie-player:not([hidden])')) ? contentHeader.querySelector('lottie-player:not([hidden])') : contentHeader.querySelector('img:not([hidden])');
							let targetGraphic = (contentHeader.querySelector(`lottie-player:nth-child(${n})`)) ? contentHeader.querySelector(`lottie-player:nth-child(${n})`) : contentHeader.querySelector(`img:nth-child(${n})`);

							if(targetGraphic)
							{
								currentGraphic.setAttribute('hidden', '');
								targetGraphic.removeAttribute('hidden');
							}

							let currentIndicator = contentHeader.querySelector(`.${classname}__indicator-n:not([aria-hidden])`);
							let targetIndicator = contentHeader.querySelector(`.${classname}__indicator-n:nth-child(${n})`);

							if(targetIndicator)
							{
								currentIndicator.setAttribute('aria-hidden', true);
								targetIndicator.removeAttribute('aria-hidden');
							}
						}

						if(contentMenu)
						{
							let currentMenuItem = contentMenu.querySelector(`.${classname}__menu-item--active`);
							let targetMenuItem =contentMenu.querySelector(`.${classname}__menu-item:nth-child(${n})`);

							if(targetMenuItem)
							{
								if(currentMenuItem)
								{
									currentMenuItem.classList.remove(`${classname}__menu-item--active`);
								}

								targetMenuItem.classList.add(`${classname}__menu-item--active`);
							}
						}
						
						region.dataset.prop = n;
					}
				};
				
				let timer = null;
				let currentProp = 1;

				[
					'resize',
					'scroll'
				].forEach(eventListener => {
					window.addEventListener(eventListener, event => {
						let props = region.querySelectorAll(`.${window.namespace}-section__prop`);

						for(let i = 0; i < props.length; i++)
						{
							let prop = props[i];
							let trigger = Math.floor(prop.getBoundingClientRect().top + (prop.getBoundingClientRect().height * 0.5));

							if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
							{
								let header = document.getElementById('header');
								
								trigger -= (header.getBoundingClientRect().height);
							}

							if(trigger >= 1)
							{
								let index = parseInt(prop.dataset.regionProp);
								
								contentRoll.setTo(index);

								return;
							}
						}

					});
				});

				contentRoll.setTo(1);
			}
		}
	});
}