'use strict';

window.namespace = document.documentElement.getAttribute('data-namespace');

//- Base Imports
require('/src/scripts/imports.js');

//- Router
require('/src/scripts/router.js');

//- Everything Else
{
	localhost_detection: {
		window.isLocalHost = Boolean(/^localhost\.?/gi.test(location.hostname));
	}
	
	scroll_direction:
	{
		window.scrollPosition = 0;
		window.scrollDirection = 'none';

		document.addEventListener('scroll', scroll => {
			let ctx = window.pageYOffset || document.documentElement.scrollTop;

			window.scrollDirection = (ctx > window.scrollPosition) ? 'down' : 'up';
			window.scrollPosition = ctx <= 0 ? 0 : ctx;
		});
	}

	scroll_intersector:
	{
		(function()
		{
			if(typeof window.CustomEvent === "function")
			{
				return false;
			}

			function CustomEvent(event, params)
			{
				params = params || {
					bubbles: false,
					cancelable: false,
					detail: null
				};
				var evt = document.createEvent('CustomEvent');

				evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

				return evt;
			}

			window.CustomEvent = CustomEvent;
		})();

		let regionEvent = new CustomEvent('regionchange');

		set_initial_region:
		{
			window.do.when(`.${window.namespace}-section`).then(region => {
				window.region = region.id;
			});
		}

		document.addEventListener('scroll', scroll => {
			let was = window.region;
			let selector = `${window.namespace}-section`;
			let sections = document.querySelectorAll(`.${selector}[role='region']`);

			for(sections.i = sections.length; sections.i-- > 0;)
			{
				let section = sections[sections.i];

				if(section.getBoundingClientRect().top <= Math.floor(window.innerHeight * .3334))
				{
					window.region = section.id;

					break;
				}
			}

			if((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight)
			{
				window.region = sections[sections.length - 1].id;
			}

			if(window.region !== was)
			{
				regionEvent.regionId = window.region;

				window.dispatchEvent(regionEvent);
			}
		});
	}

	scroll_to_element:
	{
		window.scrollToElement = function(element = null, smooth = false, align)
		{
			if(!document.documentElement.getAttribute('data-virtual-keyboard') || document.documentElement.dataset.virtualKeyboard === 'false')
			{
				element	= (typeof element === 'string') ? document.getElementById(element) : element;

				if(!element)
				{
					console.error(`scrollToElement: element ${element} not found.`);

					return;
				}

				let y = window.pageYOffset;
				
				if(!align)
				{
					y += (element.getBoundingClientRect().top);
				}
				
				else
				{
					switch(align)
					{
						case 'bottom':
							y += parseInt(element.getBoundingClientRect().top + element.getBoundingClientRect().height);
							break;

						case 'center':
							y += parseInt(element.getBoundingClientRect().top + (element.getBoundingClientRect().height / 2));
							break;

						default:
							y += element.getBoundingClientRect().top;
					}
				}

				if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
				{
					y -= (document.getElementById('header').getBoundingClientRect().height);
				}

				window.scrollTo({
					behavior: (smooth) ? 'smooth' : 'auto',
					top: Math.floor(y)
				});

				return y;
			}
		};
	}
}