'use strict';

{
	let trackPreferredLanguage = () => 
	{
		let c = false;

		try
		{
			c = navigator.language;
		}
		
		catch(err)
		{
			c = navigator.userLanguage;
		}

		finally
		{
			document.documentElement.dataset.preferredLanguage = c;
		}
	
		window.requestAnimationFrame(trackPreferredLanguage);
	};
	
	window.requestAnimationFrame(trackPreferredLanguage);
}