'use strict';

{	
	let classname = `${window.namespace}-checkbox`;
	
	window.do.always(() => {
		let selector = `${classname}:not([data-assigned])`;

		if(document.querySelectorAll(`.${selector}`).length)
		{
			document.querySelectorAll(`.${selector}`).forEach(checkbox => 
			{
				checkbox.validate = function()
				{
					let selector = this.querySelectorAll(`[required]`);

					if(!selector.length)
					{
						checkbox.classList.remove(`${classname}--invalid`);

						return true;
					}

					if(!this.querySelectorAll(`.${classname}__native[required]:checked`).length)
					{
						this.classList.add(`${classname}--invalid`);
					}
					
					else
					{
						this.classList.remove(`${classname}--invalid`);
					}
				};
				
				{
					let inputs = checkbox.querySelectorAll(`.${classname}__native`);
					
					inputs.forEach(input => {
						[
							'input',
							'blur'
						].forEach(eventListener => {
							input.addEventListener(eventListener, (event) => {
								if(/\-\-invalid\s?/gi.test(checkbox.getAttribute('class')))
								{
									checkbox.validate();
								}
							});
						});
					});
				}

				checkbox.dataset.assigned = `${window.namespace}`;
			});
		}
	});
}