"use strict";

window.do.when('[role="main"]').then(main => {
	main.querySelectorAll(`[href]`).forEach((item,index) => {
		let href = item.getAttribute('href');

		if(!/^https?:\/\//.test(href))
		{
			//- This is what sets the IOM data on main content links
			item.setAttribute('href', `${href}${location.search}`);
		}
	});
});