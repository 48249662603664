'use strict';

{
	(function()
	{
		if(typeof window.CustomEvent === "function")
		{
			return false;
		}

		function CustomEvent(event, params)
		{
			params = params || { 
				bubbles: false, 
				cancelable: false, 
				detail: null 
			};
			var evt = document.createEvent('CustomEvent');

			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

			return evt;
		}

		window.CustomEvent = CustomEvent;
	})();
	
	let breakpointEvent = new CustomEvent('breakpoint');
	let breakpoints = [
		{
			max: 299,
			min: 0,
			range: 'u'
		},
		{
			max: 599,
			min: 300, 
			range: 'xs'
		},
		{
			max: 959, 
			min: 600, 
			range: 'sm'
		},
		{
			max: 1279, 
			min: 960, 
			range: 'md'
		},
		{
			max: 1919, 
			min: 1280, 
			range: 'lg'
		},
		{
			min: 1920, 
			max: 3839, 
			range: 'xl'
		},
		{
			min: 3840,
			range: 'xx'
		}
	];
	
	if(!window.breakpoints)
	{
		window.breakpoints = breakpoints;
	}

	let trackBreakpoint = function(event) 
	{
		let currentBreakpoint = document.documentElement.dataset.breakpoint || null;
		
		for(breakpoints.i = breakpoints.length; breakpoints.i-- > 0;)
		{
			let breakpoint = breakpoints[breakpoints.i];

			if((!breakpoint.min || (breakpoint.min && window.innerWidth >= breakpoint.min)) && (!breakpoint.max || (breakpoint.max && window.innerWidth <= breakpoint.max)))
			{
				document.documentElement.dataset.breakpoint = breakpoint.range;
				window.breakpoint = breakpoint.range;

				if(currentBreakpoint !== breakpoint.range)
				{
					breakpointEvent.breakpoint = breakpoint.range;

					window.dispatchEvent(breakpointEvent);
				}

				break;
			}
		}
	};

	trackBreakpoint();

	window.addEventListener('resize', trackBreakpoint);
}