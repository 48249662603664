'use strict';

//- Bridges
	import 'wicg-inert';

//- Polyfills
	[
		"forEach",
		"some"
	].forEach(proto => {
		if(typeof NodeList.prototype[proto] !== "function") NodeList.prototype[proto] = Array.prototype[proto];
	});

//- Plugins
	window.axios = require('axios');
	window.lottie = require('lottie-web');
	window.moment = require('moment');
	window.router = require('routerjs').createRouter({
		ignoreCase: true
	});
	window.unidecode = require('unidecode');
	axios = axios.default;

//- Turnips
	window.do = {
		always: require('/src/scripts/turnips/do.always.js').always,
		when: require('/src/scripts/turnips/do.when.js').when
	};

	window.set = {
		transitioner: require('/src/scripts/vendor/@cloudfour/transition-hidden-element.js').transitionHiddenElement
	};

//- Evergreens
	require('/src/scripts/qol/live.breakpoint.js');
	require('/src/scripts/qol/live.preferred-language.js');
	require('/src/scripts/qol/live.touch-enabled.js');
	require('/src/scripts/qol/live.virtual-keyboard.js');

//- Components
	require('/src/components/checkbox/checkbox.js');
	require('/src/components/checkcard/checkcard.js');
	require('/src/components/dropdown/dropdown.js');
	require('/src/components/error/error.js');
	require('/src/components/footnotes/footnotes.js');
	require('/src/components/radio/radio.js');
	require('/src/components/radiocard/radiocard.js');
	require('/src/components/reference/reference.js');
	require('/src/components/textfield/textfield.js');

//- Includes
	//- require('/src/includes/cookie-consent/cookie-consent.js');
	require('/src/includes/footer/footer.js');
	require('/src/includes/header/header.js');
	require('/src/includes/navigation/navigation.js');
	require('/src/includes/post-navigation/post-navigation.js');

//- Regions
	require('/src/regions/benefits/benefits.js');
	require('/src/regions/contact/contact.js');
	require('/src/regions/how-it-works/how-it-works.js');
	//- NOTE: This has to be last.
	require('/src/regions/__global/__global.js');
	
//- Views
	require('/src/views/home/home.js');
	require('/src/views/local/local.js');