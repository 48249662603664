'use strict';

{
	window.do.when('#postnav').then(nav => {
		nav.querySelectorAll(`[href]`).forEach((item,index) => {
			let href = item.getAttribute('href');
			
			if(!/^https?:\/\//.test(href))
			{
				//- This is what sets the IOM data on the top nav links
				item.setAttribute('href', `${href}${location.search}`);
			}
		});

		set_postnav_contact_link:
		{
			let contactLink = nav.querySelector('[href$="/contact"]');
			
			if(contactLink)
			{
				let href = contactLink.getAttribute('href');
				
				if(/^\/(local|regional|returns)\/?/.test(location.pathname))
				{
					let path = location.pathname.split('/');
					path = path.filter(function(el)
					{
						return (el !== null && el !== '');
					});

					let prefix = path[0];
	
					contactLink.setAttribute('href', `/${prefix}${href}`);
				}
				
				contactLink.addEventListener('click', click => {
					click.preventDefault();

					let region = document.getElementById('contact');
					
					if(region)
					{
						window.scrollToElement(region);
						router.setLocation(contactLink.getAttribute('href'));
					}

					return false;
				});
			}
		}
	});

	window.addEventListener('regionchange', event => {
		window.do.when('#postnav').then(nav => {
			let selector = `${window.namespace}-document__postnav-menugroup`;
			let items = nav.querySelectorAll(`.${selector}:nth-child(1) [href]`);

			items.forEach(item => {
				if(item.matches(`[href$='${event.regionId}']`))
				{
					item.dataset.current = true;
				}

				else
				{
					delete item.dataset.current;
				}
			});
		});
	});
}