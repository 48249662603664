'use strict';

{
	window.do.when('#header').then(header => {
		let brand = header.querySelector(`.${window.namespace}-brand[href]`);

		if(brand)
		{
			let href = brand.getAttribute('href');

			//- This is what sets the IOM data on the brand link
			brand.setAttribute('href', `${href}${location.search}`);
		}
	});
}