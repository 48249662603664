'use strict';

{
	let mainId = 'connect';
	let regionId = 'contact';
	let campaignId = 'default';
	let viewId = 'default';

	if(!/connect(\/thankyou)?\/?$/gi.test(location.pathname.replace(/\/$/,""))) {
		let index = (/\/thankyou$/gi.test(location.pathname.replace(/\/$/,""))) ? -2 : -1;
		campaignId = location.pathname.replace(/\/$/,"").split("/").at(index).toLowerCase();
	}

	if(/\/thankyou\/?$/gi.test(location.pathname.replace(/\/$/,""))) {
		viewId = "thankyou";
	}
	
	console.log(campaignId);

	window.do.when(`#${mainId}`).then(main => {
		campaignId = (!main.querySelector(`[data-campaign='${campaignId}']`)) ? 'default' : campaignId;

		main.querySelectorAll(`[data-view]`).forEach(element => {
			let forView = element.getAttribute('data-view').toLowerCase();
			let attrs = [
				"hidden",
				"inert"
			];

			if(forView !== viewId)
			{
				attrs.forEach(attr => {
					element.setAttribute(attr, "");
				});

				return;
			}

			attrs.forEach(attr => {
				element.removeAttribute(attr);
			});
		});

		main.querySelectorAll(`[data-campaign]`).forEach(element => {
			let forCampaign = element.getAttribute('data-campaign').toLowerCase();
			let attrs = [
				"hidden",
				"inert"
			];

			if(forCampaign !== campaignId)
			{
				attrs.forEach(attr => {
					element.setAttribute(attr, "");
				});

				return;
			}

			attrs.forEach(attr => {
				element.removeAttribute(attr);
			});
		});
	});
}