'use strict';

window.router
	//- Weird connecting routing behavior
	.get('/connect', (req, context) => {
		require('/src/views/connect/connect.js');
	})
	.get('/connect/thankyou', (req, context) => {
		require('/src/views/connect/connect.js');
	})
	.get('/connect/:campaign', (req, context) => {
		require('/src/views/connect/connect.js');
	})
	.get('/connect/:campaign/thankyou', (req, context) => {
		require('/src/views/connect/connect.js');
	})

	//- Standardized site routing behavior
	.get('/:product/:section/:display?', (req, context) => {
		window.do.when(`#${req.params.section}`).then(region => {
			let scrollTarget = region.getBoundingClientRect().top + window.pageYOffset;

			if(location.hash && document.querySelector(location.hash))
			{
				scrollTarget = document.querySelector(location.hash).getBoundingClientRect().top + window.pageYOffset;
			}

			if(region.querySelector(`.${window.namespace}-section__content`) && /--offset/gi.test(region.querySelector(`.${window.namespace}-section__content`).getAttribute('class')))
			{
				let content = region.querySelector(`.${window.namespace}-section__content--offset`);
				let offset = Math.abs(parseInt(window.getComputedStyle(content).getPropertyValue('margin-top')));

				scrollTarget -= offset * 2;
			}

			if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
			{
				let header = document.getElementById('header');
				
				scrollTarget -= header.getBoundingClientRect().height;
			}

			window.setTimeout(() => {
				window.scrollTo({
					top: Math.floor(scrollTarget)
				});
			});
		});
	})
	.get('/:section/:display?', (req, context) => {
		window.do.when(`[id="${req.params.section}"]`).then(region => {
			let scrollTarget = region.getBoundingClientRect().top + window.pageYOffset;

			if(location.hash && document.querySelector(location.hash))
			{
				scrollTarget = document.querySelector(location.hash).getBoundingClientRect().top + window.pageYOffset;
			}

			if(region.querySelector(`.${window.namespace}-section__content`) && /--offset/gi.test(region.querySelector(`.${window.namespace}-section__content`).getAttribute('class')))
			{
				let content = region.querySelector(`.${window.namespace}-section__content--offset`);
				let offset = Math.abs(parseInt(window.getComputedStyle(content).getPropertyValue('margin-top')));

				scrollTarget -= offset * 2;
			}

			if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
			{
				let header = document.getElementById('header');
				
				scrollTarget -= header.getBoundingClientRect().height;
			}

			window.setTimeout(() => {
				window.scrollTo({
					top: Math.floor(scrollTarget)
				});
			});
		});
	});