'use strict';

{
	let regionId = 'contact';

	window.router.always(context => {
		window.do.when(`#${regionId}`).then(region => {
			let contentId = `${regionId}-${context.path.split('/').slice(-1)[0].split('?')[0]}`;		
			let querySelector = `.${window.namespace}-section__content`;
			let interaciveElements = [
				`a[href]:not([hidden]):not([inert])`,
				`button:not([disabled]):not([hidden]):not([inert]):not([tabindex='-1'])`,
				`[tabindex]:not([tabindex='-1']):not([disabled]):not([hidden]):not([inert])`
			].join(',');

			switch(Boolean(true))
			{
				case(/\-(error|loading|thankyou)$/gi.test(contentId)):
				{
					window.do.when(`${querySelector}#${contentId}`).then(context => {
						region.querySelectorAll(`${querySelector}`).forEach(content => {
							if(content.id !== contentId)
							{
								content.setAttribute('aria-hidden', 'true');
								content.setAttribute('hidden', '');
							}

							else
							{
								content.removeAttribute('aria-hidden');
								content.removeAttribute('hidden');

								window.setTimeout(() => {
									interaciveElements = content.querySelectorAll(interaciveElements);
									interaciveElements.length&& interaciveElements[0].focus();

									return;
								});
							}
						});
					});

					break;
				}

				case(/\-(contact)$/gi.test(contentId)):
				{
					region.querySelectorAll(`${querySelector}`).forEach((content, index) => {
						if(index > 0)
						{
							content.setAttribute('aria-hidden', 'true');
							content.setAttribute('hidden', '');
						}

						else
						{
							content.removeAttribute('aria-hidden');
							content.removeAttribute('hidden');

							window.setTimeout(() => {
								interaciveElements = content.querySelectorAll(interaciveElements);
								interaciveElements.length&& interaciveElements[0].focus();

								return;
							});
						}
					});
					
					break;
				}
			}
		});
	});

	window.do.when('#contact').then(region => {
		// Prefilled form for localhost
		{
			if(isLocalHost) {
				let form = document.querySelector("#contact-form form");
				
				["firstName", "lastName", "companyName", "address1", "city", "QNA(9)"].forEach(fieldName => {
					let field = form.querySelector(`[name="${fieldName}"]`);
					
					if(field) {
						field.value = "test";
					}
				});
				
				form.querySelector(`[name="email"]`).value = "test@uspstest.com";
				form.querySelector(`[name="QNA(35)"]`).value = "150";
				form.querySelector(`[name="state"]`).value = "NY";
				form.querySelector(`[name="zip5"]`).value = "11111-1111";
				form.querySelector(`[name="phone"]`).value = "(111) 111-1111";
				form.querySelector(`[name="QNA(93)"]`).checked = true;
				form.querySelector(`[name="QNA(94)"]`).checked  = true;
				form.querySelector(`[name="QNA(95)"]`).checked  = true;
				form.querySelector(`[name="QNA(96)"]`).checked  = true;
				form.querySelector(`[name="QNA(97)"]`).checked  = true;
				form.querySelector(`[name="QNA(98)"]`).checked  = true;
			}
		}
		
		region.api = {
			auth: (/^(www\.)?uspsconnect\.com$/gi.test(location.hostname)) ? 'https://www.uspsconnect.com/bridge/auth' : 'https://stage.usps-connect.mrmclient.com/bridge/auth',
			response: (/^(www\.)?uspsconnect\.com$/gi.test(location.hostname)) ? 'https://www.uspsrms.com/api/Response' : 'https://apptest.iwco.com/q1/uspsrms/api/Response'
		};
		
		region.form = region.querySelector(`.${window.namespace}-form`);
		region.standAlone = (region.getAttribute('data-standalone')) ? true : false;

		iom_failsafe_override:
		{
			window.do.when(window.macCode != "undefined").then(context => {
				window.setTimeout(() => {
					if(region.form.querySelector(`[name='macCode']`) && window.macCode) region.form.querySelector(`[name='macCode']`).value = window.macCode;
					if(region.form.querySelector(`[name='iomCode']`) && window.iomCode) region.form.querySelector(`[name='iomCode']`).value = window.iomCode;
				});
			});
		}

		region.querySelectorAll('[data-region-content]').forEach(interactor => {
			interactor.addEventListener('click', click => {
				click.preventDefault();

				let target = click.target.dataset.regionContent;

				region.querySelectorAll(`.${window.namespace}-section__content`).forEach(content => {
					if(!content.id || content.id !== target)
					{
						content.setAttribute('hidden', '');
						content.setAttribute('aria-hidden', 'true');
					}

					else
					{
						content.removeAttribute('hidden');
						content.removeAttribute('aria-hidden');

						window.setTimeout(() => {
							let formElements = content.querySelectorAll([
								`button:not([disabled]):not([hidden]):not([inert])`,
								`input:not([disabled]):not([hidden]):not([inert]):not([type='hidden']`,
								`select:not([disabled]):not([hidden]):not([inert])`,
								`textarea:not([disabled]):not([hidden]):not([inert])`
							].join(','));

							if(formElements.length)
							{
								formElements[0].focus();

								return;
							}

							let otherElements = content.querySelectorAll([
								`a[href]:not([hidden]):not([inert])`,
								`[tabindex]:not([tabindex='-1']):not([disabled]):not([hidden]):not([inert])`
							].join(','));

							otherElements.length && otherElements[0].focus();

							return;
						});
					}

					window.scrollToElement(region);
				});

				return false;
			});
		});

		region.querySelectorAll('[data-form-content]').forEach(interactor => {
			let form = interactor.closest(`.${window.namespace}-form`);

			interactor.addEventListener('click', click => {
				click.preventDefault();

				let formContent = form.querySelector(`.${window.namespace}-form__content:not([hidden])`);

				if(!click.target.dataset.bypassValidation)
				{
					formContent.querySelectorAll([
						`.${window.namespace}-checkbox`,
						`.${window.namespace}-checkcard`,
						`.${window.namespace}-dropdown`,
						`.${window.namespace}-radio`,
						`.${window.namespace}-radiocard`,
						`.${window.namespace}-textfield`
					].join(',')).forEach(input => {
						input.validate();
					});

					if(formContent.querySelector('[class*="--invalid"]'))
					{
						let formElements = formContent.querySelectorAll([
							`[class*="--invalid"] input:not([disabled]):not([inert])`,
							`[class*="--invalid"] select:not([disabled]):not([inert])`,
							`[class*="--invalid"] textarea:not([disabled]):not([inert])`
						].join(','));

						formElements[0].focus();

						window.scrollToElement(formElements[0]);

						return false;
					}
				}

				let target = click.target.dataset.formContent;

				region.querySelectorAll(`.${window.namespace}-form__content`).forEach(content => {
					if(!content.id || content.id !== target)
					{
						content.setAttribute('hidden', '');
						content.setAttribute('aria-hidden', 'true');
					}

					else
					{
						content.removeAttribute('hidden');
						content.removeAttribute('aria-hidden');

						window.setTimeout(() => {
							let formElements = content.querySelectorAll([
								`button:not([disabled]):not([hidden]):not([inert])`,
								`input:not([disabled]):not([hidden]):not([inert]):not([type='hidden']`,
								`select:not([disabled]):not([hidden]):not([inert])`,
								`textarea:not([disabled]):not([hidden]):not([inert])`
							].join(','));

							if(formElements.length)
							{
								formElements[0].focus();

								return;
							}

							let otherElements = content.querySelectorAll([
								`a[href]:not([hidden]):not([inert])`,
								`[tabindex]:not([tabindex='-1']):not([disabled]):not([hidden]):not([inert])`
							].join(','));

							if(otherElements.length)
							{
								otherElements[0].focus();
							}

							return;
						});
					}

					window.scrollToElement(region);
				});
				
				let paging = {
					is: region.querySelector(`.${window.namespace}-form__paging-page--active[data-for]`),
					target: region.querySelector(`.${window.namespace}-form__paging-page[data-for='${target}']`), 
					bemRoot: region.querySelector(`.${window.namespace}-form__paging-page[data-for]`).getAttribute('class').split(' ')[0]
				};

				if(paging.is && paging.is != paging.target)
				{
					paging.is.classList.remove(`${paging.bemRoot}--active`);
					paging.target.classList.add(`${paging.bemRoot}--active`);
				}

				return false;
			});
		});

		region.form.addEventListener('submit', submit => {
			submit.preventDefault();

			let form = submit.target;
			let formContents = form.querySelectorAll(`.${window.namespace}-form__content`);
			let formContent = form.querySelector(`.${window.namespace}-form__content:not([hidden])`);

			formContent.querySelectorAll([
				`.${window.namespace}-checkbox`,
				`.${window.namespace}-checkcard`,
				`.${window.namespace}-dropdown`,
				`.${window.namespace}-radio`,
				`.${window.namespace}-radiocard`,
				`.${window.namespace}-textfield`
			].join(',')).forEach(input => {
				input.validate();
			});

			if(formContent.querySelector('[class*="--invalid"]'))
			{
				let formElements = formContent.querySelectorAll([
					`[class*="--invalid"] input:not([disabled]):not([inert])`,
					`[class*="--invalid"] select:not([disabled]):not([inert])`,
					`[class*="--invalid"] textarea:not([disabled]):not([inert])`
				].join(','));

				formElements[0].focus();

				window.scrollToElement(formElements[0]);

				return false;
			}

			if(formContent !== Array.from(formContents).slice(-1)[0])
			{
				formContent.querySelector('[data-form-content]').click();

				return false;
			}

			let prefix = '';

			if(/^\/(local|regional|national|returns|connect)\/?/.test(location.pathname))
			{
				let path = location.pathname.split('/');
				path = path.filter(function(el)
				{
					return (el !== null && el !== '');
				});

				prefix = `/${path[0]}`;
			}

			window.router.navigate(/^\/(connect)\/?/.test(location.pathname) ? `${prefix}/loading${location.search}` : `${prefix}/contact/loading${location.search}`);

			axios
				.get(region.api.auth)
				.then(response => {

					switch(response.data.code)
					{
						case 1:
						{
							if(response.data.token)
							{
								//- Show the user the thank you page
								//- Since both success and failure states yield the thank you, we should show the user this straight away
								let bearerToken = response.data.token;
								let formData = {
									macCode: '',
									title: '',
									firstName: '',
									middleName: '',
									lastName: '',
									suffix: '',
									companyName: '',
									address1: '',
									address2: '',
									address3: '',
									city: '',
									state: '',
									zip5: '',
									zip4: '',
									phone: '',
									phoneExt: '',
									email: '',
									iomCode: '',
									sendToSales: '',
									source: 'USPSConnect',
									optIn: false,
									answers: []
								};

								Object.keys(formData).forEach(key => {
									if(form.querySelector(`[name='${key}']`))
									{
										let input = form.querySelector(`[name='${key}']`);

										if(input.type === 'checkbox')
										{
											formData[key] = (input.checked) ? true : false;
										}

										else
										{
											let decodedValue = window.unidecode(form.querySelector(`[name='${key}']`).value);
											decodedValue = decodedValue.replace(/[^A-Za-z0-9\- _\.,\#\@\&]/g, "");
											decodedValue = decodedValue.replace(/\s+/g, " ");
											decodedValue = decodedValue.replace(/^\s+|\s+$/g, "");

											formData[key] = decodedValue;
										}
									}

									if(key === 'phone')
									{
										formData[key] = formData[key].replace(/\D+/g, '');
									}

									if(key === 'answers')
									{
										form.querySelectorAll('[name^="QNA"]').forEach(input => {
											if(!/textfield/.test(input.getAttribute('data-native')))
											{
												if(input.checked)
												{
													formData.answers.push({
														answerId: parseInt(input.value),
														answerText: null
													})
												}
											}

											else
											{
												let decodedValue = window.unidecode(input.value);
												decodedValue = decodedValue.replace(/[^A-Za-z0-9\- _\.,\#\@\&]/g, "");
												decodedValue = decodedValue.replace(/\s+/g, " ");
												decodedValue = decodedValue.replace(/^\s+|\s+$/g, "");

												formData.answers.push({
													answerId: parseInt(input.dataset.qnaAnswerId),
													answerText: decodedValue
												})
											}
										});
									}
								});

								capture_iom_mac:
								{
									if(window.iomCode)
									{
										formData.macCode = window.macCode;
										formData.iomCode = window.iomCode;
									}
								}

								if(/\-/gi.test(formData.zip5))
								{
									let zip = formData.zip5.split('-');

									formData.zip5 = zip[0];
									formData.zip4 = zip[1];
								}

								formData.transactionId = (() => {
									return Date.now().toString(32) + Math.random().toString(32);
								})();

								formData.entryTimestamp = new Date().toISOString().replace(/T/gi, ' ').replace(/\..+$/gi, '');

								axios
									.post(region.api.response, formData, {
										headers: {
											'Authorization': `Bearer ${bearerToken}`
										}
									})
									.then(remoteResponse => {
										window.router.navigate(/^\/(connect)\/?/.test(location.pathname) ? `${prefix}/thankyou${location.search}` : `${prefix}/contact/thankyou${location.search}`);
									})
									.catch(error => {
										window.router.navigate(/^\/(connect)\/?/.test(location.pathname) ? `${prefix}/error${location.search}` : `${prefix}/contact/error${location.search}`);
									});
							}

							else window.router.navigate(/^\/(connect)\/?/.test(location.pathname) ? `${prefix}/error${location.search}` : `${prefix}/contact/error${location.search}`);

							break;
						}

						default: window.router.navigate(/^\/(connect)\/?/.test(location.pathname) ? `${prefix}/error${location.search}` : `${prefix}/contact/error${location.search}`);
					}
				})
				.catch(error => {
					if(error.response) console.warn(error.response.data.message);
				});

			return false;
		});
	});
}