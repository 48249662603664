'use strict';

{
	function always(callback)
	{
		if(typeof callback !== 'function')
		{
			console.log('is kill!');

			return false;
		}

		let context = function()
		{
			callback();
			
			requestAnimationFrame(context);
		};
		
		requestAnimationFrame(context);
		
		return true;
	};

	module.exports.always = always;
}