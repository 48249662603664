'use strict';

{
	window.do.when('#footer').then(footer => {
		let copyright = footer.querySelector(`.${window.namespace}-document__footer-copyright`);

		if(copyright)
		{
      let year = new Date().getFullYear();

      copyright.textContent = year;
		}
	});
}