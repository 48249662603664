'use strict';

{
	let trackTouchEnabled = () => 
	{
		document.documentElement.dataset.touchEnabled = !!('ontouchstart' in window);
		
		window.requestAnimationFrame(trackTouchEnabled);
	};
	
	window.requestAnimationFrame(trackTouchEnabled);
}