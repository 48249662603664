'use strict';

{
	let classname = `${window.namespace}-error`;
	
	window.do.always(() => {
		let selector = `${classname}:not([data-assigned])`;

		if(document.querySelectorAll(`.${selector}`).length)
		{
			document.querySelectorAll(`.${selector}`).forEach(error => 
			{
				error.show = function()
				{	
					this.removeAttribute('hidden');
					this.removeAttribute('aria-hidden');
				}

				error.hide = function()
				{
					this.setAttribute('hidden', '');
					this.setAttribute('aria-hidden', 'true');
				};

				if(error.closest('[class*="--invalid"]'))
				{
					error.show();
				}
				
				else
				{
					error.hide();
				}
				
				error.dataset.assigned = `${window.namespace}`;
			});
		}
	});
}