'use strict';

{	
	let classname = `${window.namespace}-dropdown`;
	
	window.do.always(() => {
		let selector = `${classname}:not([data-assigned])`;

		if(document.querySelectorAll(`.${selector}`).length)
		{
			document.querySelectorAll(`.${selector}`).forEach(function(dropdown)
			{
				dropdown.validate = function()
				{
					let input = this.querySelector(`.${classname}__native`);

					if(!input.required)
					{
						dropdown.classList.remove(`${classname}--invalid`);

						return true;
					}

					if(!input.value || input.value === '')
					{
						dropdown.classList.add(`${classname}--invalid`);
					}

					else
					{
						dropdown.classList.remove(`${classname}--invalid`);
					}
				};

				let input = dropdown.querySelector(`.${classname}__native`);
				
				if(input.value && input.value !== '')
				{
					input.classList.add(`${classname}__native--filled`);
				}

				[
					'change',
					'input',
					'blur'
				].forEach(function(eventListener)
				{
					input.addEventListener(eventListener, function(event)
					{
						{
							if(input.value && input.value !== '')
							{
								input.classList.add(`${classname}__native--filled`);
							}
							
							else
							{
								input.classList.remove(`${classname}__native--filled`);
							}
						}

						{
							if(dropdown.classList.contains(`${classname}--invalid`))
							{
								dropdown.validate();
							}
						}
					}, true);
				});
				
				dropdown.dataset.assigned = `${window.namespace}`;
			});
		}
	});
}