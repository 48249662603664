'use strict';

{
	let footnote = null;
	let referenceTimer = null;
	let eventFn = () => {
		if(footnote !== null)
		{
			if(referenceTimer !== null)
			{
				clearTimeout(referenceTimer);        
			}

			referenceTimer = setTimeout(function()
			{
				window.removeEventListener('scroll', eventFn);

				if(footnote !== null)
				{
					footnote.focus();

					footnote = null;
				}
			}, 50);
		}
	};
	
	window.do.always(() => {
		document.querySelectorAll(`.${window.namespace}-reference[role='link']:not([data-assigned])`).forEach(reference => {
			let footnotes = document.getElementById('footnotes');
			
			if(!footnotes)
			{
				return;
			}
			
			let footnote = footnotes.querySelector(`.${window.namespace}-footnotes__list-item:nth-child(${parseInt(reference.textContent.split(/,/gi)[0])})`);
			
			if(footnote)
			{
				reference.setAttribute('title', `Footnote ${reference.textContent.split(/,/gi)[0]}`);
				reference.setAttribute('aria-label', `Footnote ${reference.textContent.split(/,/gi)[0]}`);
			}

			reference.dataset.assigned = window.namespace;
		});
	});

	document.addEventListener('click', click => {
		let selector = `${window.namespace}-reference[role='link']`;

		if(click.target.closest(`.${selector}`))
		{
			click.preventDefault();

			let returnElement = click.target.closest(`.${selector}`);
			
			if(returnElement.closest(`.${window.namespace}--sticky`))
			{
				let region = returnElement.closest(`.${window.namespace}-section[data-prop]`);

				if(region)
				{
					let propId = parseInt(region.dataset.prop);
					
					region.dataset.returnProp = propId;
				}
			}

			let refNumber = returnElement.textContent.split(/,/gi)[0];

			footnote = document.querySelector(`#footnotes .${window.namespace}-footnotes__list-item:nth-child(${refNumber})`);

			if(footnote)
			{
				returnElement.dataset.referenceReturn = true;
				
				//- Iterate through all other footnotes and hide the return arrows of them
				{
					let footnotes = document.querySelectorAll(`.${window.namespace}-footnotes__list-item`);

					footnotes.forEach(note => {
						let arrow = note.querySelector(`.${window.namespace}-footnotes__list-return`);

						if(note !== footnote)
						{
							arrow.setAttribute('hidden', '');
						}
						
						else
						{
							arrow.removeAttribute('hidden');
						}
					});
				}

				window.addEventListener('scroll', eventFn);

				window.scrollToElement(footnote.closest('#references'));
			}

			return false;
		}
	});
}