'use strict';

//- Explore Section
{
	let regionId = 'explore';

	window.do.when(`#${regionId}`).then(region => {
		let menuItems = region.querySelectorAll('[data-choose-product]');

		menuItems.forEach(item => {
			item.addEventListener('click', function(click)
			{
				click.preventDefault();
				
				menuItems.forEach(function(menuItem)
				{
					let classname = menuItem.getAttribute('class').split(' ')[0];

					if(menuItem === item)
					{
						menuItem.classList.add(`${classname}--selected`);
					}
					
					else
					{
						menuItem.classList.remove(`${classname}--selected`);
					}
				});

				let product = this.dataset.chooseProduct;
				let featureList = region.querySelectorAll('[data-for-products]');

				featureList.forEach(function(feature)
				{
					let classname = feature.getAttribute('class').split(' ')[0];
					
					if(feature.matches(`[data-for-products~='${product}']`))
					{
						feature.classList.add(`${classname}--supported`);
					}

					else
					{
						feature.classList.remove(`${classname}--supported`);
					}
				});
				
				region.querySelectorAll('[data-cta][href]').forEach(cta => {
					cta.setAttribute('href', `${this.dataset.chooseProduct}${location.search}`);

					cta.querySelector('[data-dynamic]').textContent = this.dataset.chooseProduct;
				});
				
				return false;
			});
		});
	});
}

//- Products Section
{
	let regionId = 'products';

	window.do.when(`#${regionId}`).then(region => {
		document.addEventListener('scroll', scroll => {
			let max = 400;
			let min = 100;

			switch(document.documentElement.dataset.breakpoint.toLowerCase())
			{
				case 'xx':
				case 'xl':
				case 'lg':
				case 'md':
				{
					let x = window.scrollY / 8 + 1;

					x = (x <= min) ? min : x;
					x = (x >= max) ? max : x;

					region.style.backgroundSize = `${x}% auto`;

					break;
				}
			}
		});
	});
}

//- Video Section
{
	let regionId = 'watch';

	window.do.when(`#${regionId}`).then(region => {
		let media = region.querySelector('video');
		let poster = region.querySelector(`.${window.namespace}-media__poster`);
		
		if(poster)
		{
			poster.addEventListener('click', click => {
				click.preventDefault();

				if(media)
				{
					media.removeAttribute('aria-hidden');
					media.removeAttribute('inert');
					media.setAttribute('controls', '');

					media.focus();

					poster.setAttribute('aria-hidden', true);
					poster.setAttribute('hidden', '');
					poster.setAttribute('inert', '');
					
					media.play();
				}
				
				return false;
			});
		}
	});
}