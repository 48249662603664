'use strict';

{
	let setSiteOverflow = function(trigger = null)
	{
		trigger = trigger || document.getElementById('#navigation-trigger');
		
		if(trigger)
		{
			let site = [
				document.documentElement,
				document.body
			];

			site.forEach(context => {
				context.style.overflowY = (trigger.checked) ? 'hidden' : null;
			});
		}
	};
	
	window.do.when('#navigation').then(nav => {
		nav.trigger = null;

		window.do.when('#navigation-trigger').then(trigger => {
			nav.trigger = trigger;

			nav.querySelectorAll(`.${window.namespace}-nav__menu-item[href]`).forEach((item,index) => {
				let href = item.getAttribute('href');
				
				//- This is what sets the IOM data on the top nav links
				item.setAttribute('href', `${href}${location.search}`);
				
				item.addEventListener('click', click => {
					nav.trigger.checked = false;
				});
				
				if(item.closest(`.${window.namespace}-nav__menu-group`))
				{
					let fx = nav.querySelector(`.${window.namespace}-nav__menu-item-fx`);
					
					if(fx)
					{
						fx.dataset.animateLeftOrigin = parseInt(window.getComputedStyle(fx).getPropertyValue('left'));
						fx.dataset.animateRightOrigin = parseInt(window.getComputedStyle(fx).getPropertyValue('right'));
						
						let fxContext = fx.closest(`.${window.namespace}-nav__menu-item`);
						
						[
							'focus',
							'mouseover'
						].forEach(eventListener => {
							item.addEventListener(eventListener, event => {
								let coords = {
									direction: (fxContext.compareDocumentPosition(item) & Node.DOCUMENT_POSITION_FOLLOWING) ? 'right' : 'left'
								};
								
								coords.left = (coords.direction === 'right') ? 
									(fxContext.offsetLeft - item.offsetLeft) * -1 : 
									item.offsetLeft - fxContext.offsetLeft;

								coords.right = (coords.direction === 'right') ? 
									(window.innerWidth - item.getBoundingClientRect().right) - (window.innerWidth - fxContext.getBoundingClientRect().right) : 
									(window.innerWidth - item.getBoundingClientRect().right) + (fxContext.getBoundingClientRect().right - window.innerWidth);

								fx.removeAttribute('data-returning');

								fx.dataset.animateDirection = coords.direction;

								fx.style.left = `${coords.left}px`;
								fx.style.right = `${coords.right}px`;
							}, true);
						});
						
						[
							'blur',
							'mouseleave'
						].forEach(eventListener => {
							item.addEventListener(eventListener, event => {
								let coords = {
									left: parseInt(fx.dataset.animateLeftOrigin),
									right: parseInt(fx.dataset.animateRightOrigin)
								};

								fx.dataset.direction = (fx.dataset.direction === 'right') ? 'left' : 'right';
								fx.dataset.returning = true;

								fx.style.left = `${coords.left}px`;
								fx.style.right = `${coords.right}px`;
							});
						}, true);
					}
				}
			});
		});

		set_contact_link:
		{
			let contactButton = nav.querySelector('[href$="/contact"]');
			
			if(contactButton)
			{
				let href = contactButton.getAttribute('href');
				
				if(/^\/(local|regional|national|returns)\/?/.test(location.pathname))
				{
					let path = location.pathname.split('/');
					path = path.filter(function(el)
					{
						return (el !== null && el !== '');
					});

					let prefix = path[0];
	
					contactButton.setAttribute('href', `/${prefix}${href}`);
				}

				contactButton.addEventListener('click', click => {
					click.preventDefault();

					let region = document.getElementById('contact');
					
					if(region)
					{
						window.scrollToElement(region);
						router.setLocation(contactButton.getAttribute('href'));

						let querySelector = `.${window.namespace}-section__content:not([hidden])`;
						let content = region.querySelector(querySelector);
						
						if(content)
						{
							window.setTimeout(() => {
								let formElements = content.querySelectorAll([
									`button:not([disabled]):not([hidden]):not([inert])`,
									`input:not([disabled]):not([hidden]):not([inert]):not([type='hidden']`,
									`select:not([disabled]):not([hidden]):not([inert])`,
									`textarea:not([disabled]):not([hidden]):not([inert])`
								].join(','));

								if(formElements.length)
								{
									formElements[0].focus();

									return;
								}

								let otherElements = content.querySelectorAll([
									`a[href]:not([hidden]):not([inert])`,
									`[tabindex]:not([tabindex='-1']):not([disabled]):not([hidden]):not([inert])`
								].join(','));

								otherElements.length && otherElements[0].focus();

								return;
							});
						}
					}

					return false;
				});
			}
		}
	});

	window.addEventListener('regionchange', event => {
		window.do.when('#navigation').then(nav => {
			let selector = `${window.namespace}-nav__menu`;
			let items = nav.querySelectorAll(`.${selector}-item[href]`);

			items.forEach(item => {
				if(!item.closest(`.${selector}-group`))
				{
					if(item.matches(`[href$='${event.regionId}']`))
					{
						item.dataset.current = true;
					}

					else
					{
						delete item.dataset.current;
					}
				}
			});
		});
	});

	window.do.when('#navigation-trigger').then(trigger => {
		trigger.checked = false;

		window.addEventListener('breakpoint', breakpoint => {
			switch(breakpoint.breakpoint)
			{
				case 'md':
				case 'lg':
				case 'xl':
				case 'xx':
				{
					trigger.checked = false;

					let site = [
						document.documentElement,
						document.body
					];

					site.forEach(context => {
						context.style.removeProperty('overflow');
					});
				}
			}
		});

		trigger.addEventListener('change', setSiteOverflow);
	});
}