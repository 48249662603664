'use strict';

{
	(function()
	{
		if(typeof window.CustomEvent === "function")
		{
			return false;
		}

		function CustomEvent(event, params)
		{
			params = params || { 
				bubbles: false, 
				cancelable: false, 
				detail: null 
			};
			var evt = document.createEvent('CustomEvent');

			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

			return evt;
		}

		window.CustomEvent = CustomEvent;
	})();

	let hideKeyboardEvent = new CustomEvent('virtualkeyboardhide');
	let showKeyboardEvent = new CustomEvent('virtualkeyboardshow');
	
	let trackVirtualKeyboard = function(event)
	{
		if(Math.abs(trackVirtualKeyboard.viewportHeight - window.innerHeight) > 150)
		{
			document.documentElement.dataset.virtualKeyboard = true;

			window.dispatchEvent(showKeyboardEvent);

			trackVirtualKeyboard.viewportHeight = window.innerHeight;
		}
		
		else
		{
			document.documentElement.dataset.virtualKeyboard = false;

			window.dispatchEvent(hideKeyboardEvent);
		}
	};

	[
		'msorientationchange',
		'orientationchange', 
		'resize'
	].forEach(function(eventListener)
	{
		window.addEventListener(eventListener, trackVirtualKeyboard);
	});

	[
		'click',
		'dblclick', 
		'mousedown', 
		'mouseup',
		'focus'
	].forEach(function(eventListener)
	{
		document.addEventListener(eventListener, event => {
			if(event.target && event.target.closest('html'))
			{
				document.documentElement.dataset.virtualKeyboard = false;
				
				window.dispatchEvent(hideKeyboardEvent);
				
				trackVirtualKeyboard.viewportHeight = window.innerHeight;
			}
		});
	});

	[
		'contextmenu'
	].forEach(function(eventListener)
	{
		window.addEventListener(eventListener, event => {
			if(event.target && event.target.closest('html'))
			{
				document.documentElement.dataset.virtualKeyboard = false;
				
				window.dispatchEvent(hideKeyboardEvent);
				
				trackVirtualKeyboard.viewportHeight = window.innerHeight;
			}
		});
	});

	let init = () => {
		if(!document.documentElement)
		{
			window.requestAnimationFrame(init);
			
			return;
		}

		document.documentElement.dataset.virtualKeyboard = false;
		
		trackVirtualKeyboard.viewportHeight = window.innerHeight;
	};

	window.requestAnimationFrame(init);
}